.bw-modal {
  .modal-dialog {
    &.modal-50vw {
      max-width: 50vw;

      @media only screen and (max-width: 1024px) {
        max-width: 70vw;
        margin: $spacing2 auto;
      }
    }
    &.modal-60vw {
      max-width: 60vw;
    }
    &.modal-70vw {
      max-width: 70vw;

      @media only screen and (max-width: 1400px) {
        max-width: 80vw;
      }

      @media only screen and (max-width: 1024px) {
        max-width: 90vw;
        margin: $spacing2 auto;
      }
    }
    &.modal-80vw {
      max-width: 80vw;
    }

    .modal-content {
      .modal-header {
        padding: 0;
        margin: $spacing4 $spacing6 0;
      }
      .modal-body {
        padding: 0;
        margin: $spacing4 $spacing6 0;

        &:last-child {
          margin-bottom: $spacing6;
        }
      }
      .modal-footer {
        padding: 0;
        margin: $spacing4 $spacing4;
      }
    }
  }
}

.theme-light {
  .modal-dialog {
    .modal-header {
      border: none;
    }
  }
}

.theme-dark {
  .modal-dialog {
    .modal-header {
      border-bottom-color: transparent;
    }
  }
}

.modal {
  &-dialog {
    .modal-content {
      min-height: 50vh;

      &.no-min-height {
        min-height: auto;
      }

      .modal-header {
        padding: $spacing1 $spacing6;

        .modal-title {
          font-size: 18px;
        }

        // border: none;

        button {
          padding: 0;

          svg {
            margin: 0 !important;
          }
        }
      }

      .modal-body {
        padding-left: $spacing6;
        padding-right: $spacing6;
      }

      .modal-footer {
        border: none;
      }
    }

    .rs-picker-menu {
      z-index: 1060;
    }
  }
}

.add-camera {
  .dark-image {
    & path {
      stroke: $gray-warm-darker-aa;
      fill: $secondary-color;
    }
  }
}

.custom-modal {
  .modal-dialog {
    max-width: 40%;

    @media only screen and (max-width: 1400px) {
      max-width: 50%;
    }

    @media only screen and (max-width: 1024px) {
      max-width: 90%;
      margin: $spacing2 auto;
    }
  }
}

.observation-image {
  .modal-dialog {
    max-width: 70% !important;

    @media only screen and (max-width: 1400px) {
      max-width: 80%;
    }

    @media only screen and (max-width: 1024px) {
      max-width: 90%;
      margin: $spacing2 auto;
    }
  }
}
