.nav {
  &-tabs {
    border: none;

    .nav-item {
      .nav-link {
        font-weight: 600;
        border: none;
        // border-bottom: 2px solid $secondary-color;
        color: $gray-warm-darker-aa;
        padding: $spacing2 $spacing2;
      }

      .active {
        background-color: transparent;
        color: $secondary-color;
        -webkit-box-shadow: inset 0px -2px 0px 0px $primary-color;
        -moz-box-shadow: inset 0px -2px 0px 0px $primary-color;
        box-shadow: inset 0px -2px 0px 0px $primary-color;
      }
    }
    &-light {
    }
    &-dark {
      .nav-link {
        color: $gray-warm !important;
      }
      .active {
        color: $white !important;
      }
    }
  }
}

.tab {
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .active {
      display: flex;
    }
  }

  &-pane {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
