.settings {
  flex: 1;
  justify-content: center;

  .tabs-container {
    .alerts-table {
      margin-top: $spacing1;

      .table {
        tbody {
          tr {
            height: 72px;
            &:hover td {
              background-color: #3afad130; /* or #000 */
            }
          }

          td {
            span {
              margin: 0 $spacing8 $spacing8 0;
              padding: $spacing8;
              border-radius: $spacing8 / 2;
              // text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .loader-layout {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  .user-title {
    font-size: $fontSize6;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      font-weight: 500;
    }
  }
  .user-role {
    font-size: $fontSize1;
    margin: 0;
    padding: $spacing1 $spacing2;

    &-true {
      border-color: $purple-dark !important;
      color: $purple-dark;

      &-dark {
        background-color: $purple-dark;
        color: $white;
      }
    }
    &-false {
      border-color: $blue-dark !important;
      color: $blue-dark;

      &-dark {
        background-color: $blue-dark;
        color: $white;
      }
    }
  }
}
