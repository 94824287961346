.card {
  font-size: $fontSize1;
  min-height: 180px;

  &-dark {
    background-color: $secondary-color;
    color: $white;
    .icon {
      & path {
        stroke: $white;
      }
    }

    .card-footer {
      border-color: $gray-warm-darker-aa;
      color: $gray-middle !important;
      span {
        color: $white !important;
      }
    }
  }

  .trend {
    padding: 0 $spacing2;
    border-radius: $spacing1;
    height: $size6;
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: $fontSize1;
    white-space: nowrap;
  }

  .card-footer {
    background-color: transparent;
    color: $gray-warm-darker-placeholder;
    span {
      color: $secondary-color;
    }
  }
}
