.pagination {
  padding: 0;
  margin: 0;
  font-size: 14px;
  justify-content: center;
  font-weight: 500;

  .page-link {
    font-size: $fontSize2 !important;
    background-color: $white;
    border: $gray-middle 1px solid;
    padding: $spacing2 $spacing4;
    color: $gray-warm-darker-placeholder;
    text-decoration: none;
    height: 40px;

    &:hover {
      // text-decoration: none;
    }
    &:focus {
      box-shadow: 0 0 0 $size1 #{$primary-color + '40'};
      // box-shadow: var($primary-color-light);
    }
  }

  .active {
    .page-link {
      background-color: $gray-subtle;
      color: $secondary-color;
      font-weight: 600;
    }
  }

  &-dark {
    .page-link {
      background-color: $secondary-color-compliment;
      border-color: $gray-warm-darker-aa;
    }

    .active {
      .page-link {
        background-color: $secondary-color;
        color: $white;
        font-weight: 600;
      }
    }
  }
}

.custom-pagination {
  // margin: $spacing9 0 0;

  .page-item {
    .page-link {
      font-size: $fontSize2 !important;
      background-color: transparent;
      border: none;
      padding: $spacing2 $spacing4;
      color: $secondary-color;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
    .page-link,
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $spacing2;

      &:focus {
        box-shadow: 0 0 0 3px #{$primary-color + '60'};
      }
    }
  }

  .pagination-custom-item,
  .active {
    .page-link {
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0 10px;
      background-color: $primary-color-light-1;
      border-radius: 50%;
    }
  }

  &-dark {
    .page-item {
      .page-link {
        color: $white;
      }
    }

    .pagination-custom-item {
      .page-link {
        background-color: $primary-color;
        color: $secondary-color;
      }
    }

    .active {
      .page-link {
        color: $secondary-color;
      }
    }
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    border-color: #dee2e6;
  }
}
