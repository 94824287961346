.camera-analytics {
  &-buttons {
    display: flex;

    .dropdown {
      margin-right: $spacing3;

      .dropdown-menu,
      .dropdown-toggle {
        min-width: 200px;
      }

      .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      height: 40px;
    }
  }

  .nav-tabs {
    overflow: hidden;

    .nav-item {
      .nav-link {
        height: 100%;
        padding: 0 $spacing4;
        margin: 0;
      }
      .active {
        box-shadow: none;
        background-color: $primary-color-light-1;
        border-radius: 0;
      }
    }

    &-dark {
      .active {
        // background-color: $secondary-color-compliment !important;
        color: $secondary-color !important;
      }
    }
  }

  .incidents-count {
    display: flex;

    .custom-card {
      // width: 200px;
      flex: 1;
    }
  }

  .incidents-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing3;

    .content {
      flex: 1;
      padding: 0 $spacing3;

      .scenario {
        width: fit-content;
        margin-top: $spacing3;
        padding: $spacing3;
        border-radius: 5px;
      }
    }
  }
}
