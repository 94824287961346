body {
  font-family: $font-main !important;
  background-color: $white;
  color: $text-color;
  font-size: $fontSize2;
  font-weight: 400;
}

#root {
}

.master-layout {
  min-height: 100vh;
  justify-content: center;

  @media (min-width: 992px) {
    // height: 100%;
    display: flex;

    .side-nav {
      z-index: 2;
      min-width: 72px;
      display: flex;
      justify-content: center;
    }
  }
}

.flex-1 {
  flex: 1;
}

.theme-light {
  body,
  .modal-content,
  .action-header,
  .light {
    background-color: $white;
    color: $secondary-color;

    hr {
      border-color: $gray-middle;
    }

    .sub-color {
      color: $gray-warm-darker-aa;
    }
  }
}

.theme-dark {
  body,
  .modal-content,
  .action-header,
  .dark {
    background-color: $secondary-color;
    color: $white;

    hr {
      border-color: $gray-warm-darker-aa;
    }

    .sub-color {
      color: $gray-warm;
    }

    .icon {
      & path {
        stroke: $white;
      }
    }

    .navbar-nav {
      .nav-link {
        &:hover {
          background-color: $secondary-color-compliment;
        }
      }
      .active {
        background-color: $secondary-color-compliment;
      }
    }

    .side-nav {
      min-width: 72px;
      border-right-color: $secondary-color-compliment !important;
    }

    .comment {
      background-color: $secondary-color-compliment !important;
    }
  }
}

.mx-master,
.px-master {
  margin-top: $spacing2;
  margin-bottom: $spacing7;
}

h3 {
  font-size: $fontSize2;
  font-weight: 600;
  line-height: 20px;
}

h4 {
  font-size: $fontSize8;
  font-weight: 600;
  line-height: 38px;
}

h5 {
  font-size: $fontSize7;
  font-weight: 600;
  line-height: 38px;
}

h6 {
  font-size: $fontSize4;
  font-weight: 600;
}

.weight-400 {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.h-40px {
  height: 40px;
}

.border-dark {
  border-color: $gray-warm-darker-aa !important;
}

.border-light {
  border-color: $gray-middle !important;
}

.border-radius {
  border-radius: 10px;
}

.filter-button {
  position: relative;

  .badge {
    top: -6px;
    right: -6px;
    position: absolute;
    display: flex;
    height: 22px;
    width: 22px;
    justify-content: center;
    align-items: center;
    font-size: $fontSize1;
    font-weight: 600;
  }
}

.camera-name {
  background-color: $gray-light;
  margin-right: $spacing2;
  padding: $spacing2 $spacing3;
  color: $secondary-color;
  white-space: nowrap;
}

.loader {
  svg {
    width: auto !important;
    height: auto !important;
  }
}

.empty-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input[type='radio'] {
  border-color: $gray-warm;
  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    // background-image: url("../assets/icons/check-green.svg");
  }
  &:focus {
    border-color: $primary-color-light;
    box-shadow: 0 0 0 0.25rem transparentize($primary-color, 0.75);
  }
}

.form-switch {
  .form-check-input {
    border-color: $gray-warm;
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    &:focus {
      border-color: $primary-color-light;
      box-shadow: 0 0 0 0.25rem transparentize($primary-color, 0.75);
    }
  }
}

.check-box {
  .form-check-input {
    border-color: $gray-warm;
    &:checked {
      background-color: $white;
      border-color: $primary-color;
      background-image: url('../assets/icons/check-green.svg');
    }
    &:focus {
      border-color: $primary-color-light;
      box-shadow: 0 0 0 0.25rem transparentize($primary-color, 0.75);
    }
  }
}

.not-check-all {
  .form-check-input {
    border-color: $gray-warm;
    &:checked {
      background-color: $white;
      border-color: $primary-color;
      background-image: url('../assets/icons/uncheck-green.svg');
    }
    &:focus {
      border-color: $primary-color-light;
      box-shadow: 0 0 0 0.25rem transparentize($primary-color, 0.75);
    }
  }
}

.bg-danger {
  background-color: $red-color-600 !important;
}

.add-cursor {
  cursor: pointer;
}

//user-profile
.user-profile {
  .dropup {
    .profile-toggle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;

      &::after {
        display: none;
      }
    }
  }
}

.language-dropdown {
  .dropdown {
    &-toggle {
      // padding: $spacing8 $spacing7;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      // width: 100%;
      text-align: left;

      &::after {
        display: none;
      }

      &:hover,
      &:focus,
      &:active {
        color: transparent !important;

        svg {
          & path {
            stroke: transparent;
          }
        }
      }
    }

    // &-menu.show{
    //   width: auto !important;
    //   min-width: auto !important;
    // }

    // &-item {
    //   width: fit-content;
    // }
  }
}

.loading-image {
  width: 90px;
  height: 50px;
  background-color: $gray-light;

  &-dark {
    background-color: $secondary-color-compliment;
  }
}

.blur-filter {
  filter: blur(1.6px) brightness(0.4);
}

.no-hover {
  &:active,
  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.hidden-scroll-y {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.selected-row {
  &-light {
    --bs-table-bg: #{$gray-subtle};
  }
  &-dark {
    --bs-table-bg: #323539;
    // --bs-table-hover-bg: $secondary-color-compliment;
  }
}

.dark-color {
  color: $secondary-color !important;
}
.white-color {
  color: $white !important;
}

.remove-bottom-stroke {
  stroke: blue !important;
}

.light-gray {
  color: $gray-warm-darker-placeholder !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
