$spacing1: 0.25rem; //4px
$spacing2: 0.5rem; //8px
$spacing3: 0.75rem; //12px
$spacing4: 1rem; //16px
$spacing5: 1.25rem; //20px
$spacing6: 1.5rem; //24px
$spacing7: 2rem; //32px
$spacing8: 2.5rem; //40px
$spacing9: 3rem; //48px
$spacing10: 4rem; //64px
$spacing11: 5rem; //80px
$spacing12: 6rem; //96px
$spacing13: 8rem; //128px
$spacing14: 10rem; //160px
$spacing15: 12rem; //192px
$spacing16: 14rem; //224px
$spacing17: 16rem; //256px

.ms-12 {
  margin-left: $spacing3 !important;
}

.ps-12 {
  padding-left: $spacing3 !important;
}

.me-12 {
  margin-right: $spacing3 !important;
}

.pe-12 {
  padding-right: $spacing3 !important;
}

.mx-12 {
  margin-left: $spacing3 !important;
  margin-right: $spacing3 !important;
}

.my-12 {
  margin-top: $spacing3 !important;
  margin-bottom: $spacing3 !important;
}

.mt-12 {
  margin-top: $spacing3 !important;
}

.mb-12 {
  margin-bottom: $spacing3 !important;
}

.ms-16 {
  margin-left: $spacing4 !important;
}

.me-16 {
  margin-right: $spacing4 !important;
}

.mt-16 {
  margin-top: $spacing4 !important;
}

.mb-16 {
  margin-bottom: $spacing4 !important;
}

.mt-20 {
  margin-bottom: $spacing5 !important;
}
.mb-20 {
  margin-bottom: $spacing5 !important;
}
.ms-20 {
  margin-left: $spacing5 !important;
}
.me-20 {
  margin-right: $spacing5 !important;
}

.mt-24 {
  margin-top: $spacing6 !important;
}

.mb-24 {
  margin-bottom: $spacing6 !important;
}

.ms-24 {
  margin-left: $spacing6 !important;
}

.me-24 {
  margin-right: $spacing6 !important;
}

.mt-32 {
  margin-top: $spacing7 !important;
}

.pt-32 {
  padding-top: $spacing7 !important;
}

.mb-32 {
  margin-bottom: $spacing7 !important;
}
.pb-32 {
  padding-bottom: $spacing7 !important;
}

.me-32 {
  margin-right: $spacing7 !important;
}

.ms-32 {
  margin-left: $spacing7 !important;
}

.p-24 {
  padding: $spacing6 !important;
}
