.login {
  height: 100vh;
  background-color: $gray-light;

  &-container {
    width: 360px;
    display: flex;
    flex-direction: column;
    margin: 0 140px 0 auto;

    img {
      margin-bottom: $spacing11;
    }
  }

  &-background {
    background-image: url('../../assets/images/login-background.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.theme-dark .login {
  background-color: $secondary-color;
}
