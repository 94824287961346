.observation {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-buttons {
      display: flex;

      .btn {
        white-space: nowrap;
      }

      &-priority {
        display: none;

        @media (max-width: 991px) {
          display: block;
        }
      }

      @media (max-width: 991px) {
        overflow: scroll;
      }
    }

    &-priority {
      display: flex;
      align-items: center;

      p {
        margin: 0 $spacing2 0 0;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  &-body {
    &-contect {
      @media (max-width: 991px) {
        margin-top: $spacing6;
      }
    }
  }

  &-comments {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .name {
      margin-bottom: $spacing2;
    }

    .message {
      width: fit-content;
      margin: 0;
      padding: $spacing2 $spacing3;
    }

    .comment {
      &-receiver {
        margin-bottom: $spacing4;
        display: flex;
        flex-direction: column;

        .message {
          background-color: $blue-light;
          border-radius: 0px $spacing2 $spacing2 $spacing2;
        }
      }

      &-sender {
        margin-bottom: $spacing4;
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        .message {
          background-color: $primary-color;
          border-radius: $spacing2 0px $spacing2 $spacing2;
        }
      }
    }
  }

  &-input {
    padding: $spacing4;

    &-light {
      background-color: $gray-subtle;
    }

    &-dark {
      background-color: $secondary-color-compliment;

      input {
        color: $white;

        &::placeholder {
          color: $gray-middle;
        }
      }

      .btn {
        background-color: $gray-warm-darker-aa;
      }
    }

    input {
      background-color: transparent;
      border: none;
    }
  }
}
