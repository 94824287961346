.form {
  &-label {
  }

  &-control {
    &-input {
      max-width: 360px;
      // width: auto;
      height: $size8;
      padding: $spacing2 $spacing4;

      &:focus,
      &:active,
      &:visited {
        box-shadow: 0 0 0 5px transparentize($primary-color-light, 0);
        border: 0;
      }
    }

    &-dark {
      background-color: $secondary-color-compliment;

      &:focus {
        background-color: $secondary-color;
        color: $white;
      }

      &::placeholder {
        color: $gray-middle;
      }
    }
  }
}

.input {
  &-light {
  }
  &-dark {
    input {
      background-color: $secondary-color-compliment;
      color: $white;
      border-color: $gray-warm-darker-aa;

      &:focus {
        background-color: $secondary-color;
        color: $white;
      }

      &:disabled {
        background-color: $secondary-color-compliment-50;
      }

      &::placeholder {
        color: $gray-middle;
      }
    }
  }

  &-default {
    &-width {
      width: 220px;
    }
  }
}

input {
  outline: none;
  border-radius: 5px;
  border: 1px solid #dee2e6;

  &:focus,
  &:active,
  &:visited {
    box-shadow: 0 0 0 5px #adebc4;
    border: 0;
  }
}
