.table {
  margin: 0;

  thead {
    tr {
      height: 44px;
      vertical-align: middle;
    }

    td {
      padding: 0 $spacing4;
    }
  }

  tbody {
    tr {
      height: 80px;

      &:last-child {
        border-color: transparent;
      }
    }

    td {
      vertical-align: middle;
      padding: 0 $spacing4;
    }
  }
}

.alerts-table {
  .table-light {
    --bs-table-bg: white;
    --bs-table-hover-bg: #{$gray-subtle};

    thead td {
      background: $gray-subtle;
    }

    tr {
      border-color: $gray-middle;
    }
  }

  .table-dark {
    --bs-table-bg: #1f2321;

    tr {
      border-color: $gray-warm-darker-aa;
    }
  }

  .tr-pagination {
    position: relative;
    height: 68px !important;

    &:hover {
      --bs-table-hover-bg: transparent;
    }

    .td-pagination {
      width: 100%;
      position: absolute;
      padding: 0 $spacing5;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      justify-content: space-between;
    }
  }

  .show-more-cameras {
    color: $gray-warm-darker-placeholder;
    border-bottom: 1px dotted $gray-warm-darker-placeholder;
  }
}

.table-bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing9;
}

.action-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: $spacing4 0;
}
