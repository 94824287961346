.multi-chart {
}

.donut-chart {
  .recharts-pie {
    &:focus {
      outline: none !important;
    }
  }
}
