.bottom-toast {
  background-color: $secondary-color-compliment;
  color: $primary-color-light-1;
  position: fixed;
  bottom: $spacing6;
  width: fit-content;
  height: 30px;
  align-items: center;
  display: flex;
  font-weight: 600;
  border-radius: $spacing2;
  padding: $spacing2 0 $spacing2 $spacing3;
  z-index: 3;

  &-loader {
    svg {
      path {
        stroke: white;
      }
    }
  }

  &-button {
    background-color: $secondary-color-compliment;
    color: $white;
    border-radius: 0 $spacing2 $spacing2 0;
    border-left: 1px solid $primary-color-light-1;
    height: 30px;
    padding: $spacing1 $spacing3;

    &:hover,
    &:active,
    &:first-of-type:active,
    &.show,
    &:focus-visible,
    &:disabled {
      background-color: $secondary-color-compliment !important;
    }
    &:focus {
      // box-shadow: 0 0 0 3px #{$secondary-color + '40'}
    }
  }

  &-dark {
    background-color: $primary-color-light-1;
    color: $secondary-color;

    .bottom-toast-button {
      background-color: $primary-color-light-1;
      color: $secondary-color;
      border-left: 1px solid $secondary-color;

      &:hover,
      &:active,
      &:first-of-type:active,
      &.show,
      &:focus-visible,
      &:disabled {
        background-color: $primary-color-light !important;
      }
      &:focus {
        // box-shadow: 0 0 0 3px #{$primary-color-light-1 + '40'}
      }

      svg {
        & path {
          stroke: $secondary-color;
        }
      }
    }
  }
}
