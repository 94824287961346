.create-alert-rule {
  .container {
    .action-item {
      .title-div {
        display: flex;
        .circle {
          background-color: $blue-dark;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: $spacing4;
          width: 48px;
          height: 48px;
        }
      }

      .body-div {
        flex: 1;
      }

      .condition-layout {
        display: flex;
        flex-direction: column;
        padding: $spacing3 $spacing6;
        margin-bottom: $spacing3;

        &-light {
          background-color: $gray-subtle;
        }
        &-dark {
          background-color: $secondary-color;
          border-color: $primary-color-dark !important;

          .sub-color {
            color: $white;
          }
        }
      }

      .interval-dropdowns {
        display: flex;

        .dropdown-menu {
          max-height: 200px;
          overflow-x: auto;
        }
      }
    }
  }

  .btn {
    &:hover {
      text-decoration: none;
    }
  }
}
