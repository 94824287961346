$primary-color: #37d06f;
$primary-color-dark: #29ad59;
$primary-color-light: #adebc4;
$primary-color-light-1: #d6f5e1;

$secondary-color: #1f2321;
$secondary-color-compliment: #2f3a34;
$secondary-color-compliment-50: #2f3a3450;

$gray-subtle: #f7f8f7;
$gray-light: #e6eae6;
$gray-middle: #cfd3cf;
$gray-warm: #96a097;
$gray-warm-darker-placeholder: #6b7272;
$gray-warm-darker-aa: #6f7676;

$red-color-25: #fffbfa;
$red-color-50: #fef3f2;
$red-color-100: #fee4e2;
$red-color-200: #fecdca;
$red-color-300: #fda29b;
$red-color-400: #f97066;
$red-color-500: #f04438;
$red-color-600: #d92d20;
$red-color-700: #b42318;
$red-color-800: #912018;
$red-color-900: #7a271a;

$yellow-color-25: #fffcf5;
$yellow-color-50: #fffaeb;
$yellow-color-100: #fef0c7;
$yellow-color-200: #fedf89;
$yellow-color-300: #fec84b;
$yellow-color-400: #fdb022;
$yellow-color-500: #f79009;
$yellow-color-600: #dc6803;
$yellow-color-700: #b54708;
$yellow-color-800: #93370d;
$yellow-color-900: #7a2e0e;

$green-color-25: #f6fef9;
$green-color-50: #ecfdf3;
$green-color-100: #d1fadf;
$green-color-200: #a6f4c5;
$green-color-300: #6ce9a6;
$green-color-400: #32d583;
$green-color-500: #12b76a;
$green-color-600: #039855;
$green-color-700: #027a48;
$green-color-800: #05603a;
$green-color-900: #054f31;

$purple: #b468fa;
$purple-dark: #7c30c2;
$purple-light: #f0e7f8;

$magenta: #dd56bb;
$magenta-dark: #b22b90;
$magenta-light: #f9e6f4;

$teal: #47b5bc;
$teal-dark: #0d7b82;
$teal-light: #e8f6f7;

$blue: #7989ff;
$blue-dark: #4b5bd1;
$blue-light: #e3e6f8;

$yellow: #f0a739;
$yellow-dark: #a9600e;
$yellow-light: #fef7ec;

$white: #ffffff;
$black: #000000;

$text-color: $secondary-color;

$nav-color-light: $white;
$nav-color-dark: $secondary-color;

.background {
  &-error {
    background-color: $red-color-500;
  }
  &-warning {
    background-color: $yellow-color-500;
  }
  &-success {
    background-color: $green-color-500;
  }
}
