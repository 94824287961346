.blur-wrap {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  border: #35c6eb 2px solid;
  cursor: move;
}

.bounding-box {
  background-color: #35c6eb;
  display: inline-flex;
  position: absolute;
  padding: 0 $spacing2;
  white-space: nowrap;

  &-small-view {
    padding: 0 $spacing1;
    font-size: 8px;
  }
}

.blur {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  filter: blur(2px);
}

.image-annotoation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  .konvajs-content {
    canvas {
      // background-color: red;
    }
  }
  // background: 'black';
}
