//RS Calendar

// .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
//   color: #000
// }
.rs-picker-toggle-value {
  color: #000 !important;
  font-size: 1rem;
}

.rs-btn-default {
  padding: $spacing2 $spacing3 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: $size8;
  display: flex;
  align-items: center;
  border-radius: $size8 / 2;

  .rs-picker-toggle-textbox {
    font-weight: 600;
  }

  .rs-picker-toggle-value {
    font-size: $fontSize2;
    font-weight: 600;
  }
}

.light-theme {
  border-color: $gray-middle;
  color: $secondary-color;

  .rs-btn {
    background-color: $white;
  }
}
.dark-theme {
  .rs-picker-toggle-value {
    color: $white !important;
  }

  .rs-btn {
    background-color: $secondary-color-compliment !important;
    border: none;
  }
  input {
    background-color: $secondary-color-compliment;
    color: $white;
  }
}

.rs-picker-toggle-clean {
  position: relative;
  right: 0 !important;
  top: 0 !important;
  margin-left: $spacing2;
}

.rs-picker-toggle-caret {
  position: relative;
  right: 0 !important;
  top: 0 !important;
  margin-left: $spacing2;
}

.rs-picker-menu {
  z-index: 1060;

  .rs-calendar {
    .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
      box-shadow: inset 0 0 0 1px #049576;
    }

    .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
      background-color: $primary-color-dark;
      color: $white;
    }

    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
      background-color: $primary-color-dark;
    }

    .rs-calendar-table-cell-in-range::before {
      background-color: $primary-color-light-1;
    }

    .rs-calendar-month-dropdown-cell-active
      .rs-calendar-month-dropdown-cell-content {
      background-color: $primary-color-dark;
    }

    .rs-calendar-month-dropdown-cell-content:hover {
      background-color: $primary-color-light-1;
      color: $primary-color-dark;
    }

    .rs-calendar-month-dropdown-year-active {
      color: $primary-color-dark;
    }

    .rs-calendar-time-dropdown-cell-active {
      background-color: $primary-color-dark;
      color: $white;
    }

    .rs-calendar-time-dropdown-cell:hover {
      background-color: $primary-color-light-1;
      color: $primary-color-dark;
    }
  }

  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: $primary-color-dark;
  }
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: $primary-color;
}

.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: $primary-color;
}
