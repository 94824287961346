@import 'bootstrap/scss/bootstrap.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import 'rsuite/dist/rsuite.css';
@import './spacing';
@import './sizes';
@import './colors';
@import './fonts';
@import './layout';

//components
@import './components/components';

//pages
@import './pages/pages';
