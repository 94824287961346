.user-information {
  min-width: 360px;
  margin: 0 auto;

  &-input {
    margin: $spacing7 0;
  }

  &-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-transform: uppercase;
    margin: 0 auto $spacing2;
    font-size: $fontSize6;

    &::after {
      display: none;
    }
  }
}
