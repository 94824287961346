.smart-alerts {
  flex: 1;

  .alerts-table {
    .table {
      tbody {
        tr {
          height: 80px;

          &:hover td {
            background-color: #3afad130;
          }

          .alert-rule {
            display: flex;
            align-items: center;

            svg {
              margin-right: $spacing3;
            }

            p {
              margin: 0;
            }

            span {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }

  .btn {
    &:hover {
      text-decoration: none;
    }
  }
}
