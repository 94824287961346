.observation-image-container-offline {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: $spacing3;
    font-weight: 500;
    line-height: 20px;
  }
}

.theme-light {
  .observation-image-container-offline {
    background-color: $secondary-color;
    color: $white;
  }
}

.theme-dark {
  .observation-image-container-offline {
    background-color: $white;
    color: $secondary-color;
  }
}
