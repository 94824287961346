.navbar {
  height: 100%;
  // align-items: start;

  &-brand {
    margin: 0;
  }

  &-nav {
    .nav-link {
      margin-bottom: $spacing2;
      padding: $spacing3 $spacing3 !important;
      display: flex;
      justify-content: center;
      border-radius: $spacing2;

      &:hover {
        background-color: $primary-color-light-1;
      }

      svg {
        margin: 0;
        font-size: 1.5rem;

        & path {
          // fill: $secondary-color-300;
        }
      }

      @media (max-width: 991px) {
        justify-content: flex-start;

        span {
          margin-left: $spacing8;
        }
      }
    }

    .dropdown-item {
      border-radius: 0;
    }

    .active {
      font-weight: 600;
      background-color: $primary-color-light-1;
      svg path {
        // fill: $secondary-color;
      }
    }

    .bottom-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-title {
        margin: 0;
        padding: $spacing1 $spacing3;
        font-size: 10px;
        background: $gray-light;
      }

      .dropdown-menu {
        width: 200px;
      }
    }
  }

  @media (min-width: 992px) {
    flex-direction: column;
    height: 100%;
    position: fixed;

    &-collapse {
      align-items: flex-start;

      .navbar-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        padding: $spacing6 0 $spacing4;
      }
    }
  }

  &-dark {
    .nav-link {
      color: $white;
    }
  }
}

.bg-light {
  background-color: $nav-color-light !important;
}
.bg-dark {
  background-color: $secondary-color !important;
}

.nav-link {
  color: $secondary-color;

  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

.admin-user {
  background-color: $purple-dark;
  color: $white;
  padding: $spacing2 $spacing3;
  border-radius: 5px 5px 0 0;
}
.non-admin-user {
  background-color: $blue-dark;
  color: $white;
  padding: $spacing2 $spacing3;
  border-radius: 5px 5px 0 0;
}
